.offscreen {
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap !important;
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
}

.video-js .vjs-picture-in-picture-control, .vjs-big-play-noDisplay .vjs-big-play-button {
  display: none;
}

.vjs-modal-dialog.vjs-text-track-settings {
  height: 100%;
}

#modalClosebtn button:focus {
  outline: 2px solid highlight;
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: 2px;
}

#modalClosebtn button:focus-visible {
  outline: 2px solid highlight;
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: 2px;
}

.video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before, .video-js .vjs-control:focus {
  text-shadow: none;
}

.video-js .vjs-slider:focus {
  text-shadow: none;
  box-shadow: none;
}

.vjs-text-track-settings select {
  color: #000;
}

.video-js.vjs-layout-x-small .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 7.5em;
}

.vjs-menu-item.vjs-subtitles-menu-item span {
  text-transform: capitalize;
}

